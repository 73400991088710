<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark class="mb-4 v-toolbar-header">
        <v-toolbar-title aling="center">Visor de Movimientos</v-toolbar-title>
        <v-spacer></v-spacer>

      </v-toolbar>
      <v-card flat>
        <v-container fluid>
          <PlusMenu :menuItems="menuItems" icon="mdi-arrow-down-drop-circle-outline"/>
          <!-- <v-row>
            <v-col cols="12" sm="6" :md=medida>
              <v-card class="mx-auto" color="info" dark>
                <v-card-text>
                  <div class="text-h6 mb-2">Cotizaciones y Ordenes de Compra</div>
                  <div class="text-h1 font-weight-bold">{{ itemsQuoteRequest.length }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon @click="view = 'quoteRequest'">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" :md=medida>
              <v-card class="mx-auto" color="success" dark>
                <v-card-text>
                  <div class="text-h6 mb-2">Compras y Entregas</div>
                  <div class="text-h1 font-weight-bold">{{ itemsPurchaseOrder.length }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon @click="view = 'purchaseOrder'">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" :md=medida v-if="isAdmin">
              <v-card class="mx-auto" color="primary" dark>
                <v-card-text>
                  <div class="text-h6 mb-2">Movimientos de Inventario</div>
                  <div class="text-h1 font-weight-bold">{{ movements.length }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon @click="view = 'movements'">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row> -->
        </v-container>
        <v-card-text>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
            class="mb-4"></v-text-field>
          <v-data-table :headers="headersMovements" :items="processedItems" 
          :search="search" class="elevation-1 custom-font" v-if="view === 'movements' && isAdmin">
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip :color="getColor(item.estado)" dark>
                {{ item.estado }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="viewItem(item)">
                mdi-eye
              </v-icon>
            </template>
            <template v-slot:no-data>
              No hay items
            </template>
          </v-data-table>
          <v-data-table :headers="headersRequests" :items="view === 'quoteRequest' ? itemsQuoteRequest : itemsPurchaseOrder" 
            :search="search" class="elevation-1 custom-font" v-if="view === 'quoteRequest' || view === 'purchaseOrder'">
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip :color="getColor(item.estado)" dark>
                {{ item.estado }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="viewQuoteOrPurchase(item)">
                mdi-eye
              </v-icon>
            </template>
            <template v-slot:no-data>
              No hay items
            </template>
          </v-data-table>
        </v-card-text>

      </v-card>
    </v-card>

    <v-dialog v-model="gestRequest" width="290px" :key="keyStock">
      <CalendarMultiple v-on:saveAndClose="saveAndClose"/>
    </v-dialog>
    <v-dialog v-model="gestRequestView" max-width="1000px" :key="keyView" >
      <requestView :request="requestView" v-on="{ closeModal }" :quoteRequest="quoteRequest" :purchaseOrder="purchaseOrder" :purchase="purchase" />
    </v-dialog>
    <div>
      <ReportDataPdfBasic
        v-if="dataReady"
        name="componentPdf"
        title="Ultimos Movimientos"
        :elementsPerPag=20
        :headerAll=false
        :headers="headers"
        :items="processedItems"
        :inf="hoy"
        ref="pdfComponent"
      />

    </div>
  </div>

</template>
<script>
import axios from "axios";
import api from "../../conf/ruta-base";
import UserProfile from "../../data";
import viewEmployee from "./view.vue";
import PlusMenu from '../../components/plusMenu.vue'
import request from "./request.vue";
import { format } from "@formkit/tempo"
import ReportDataPdfBasic from "../../components/report-data-basic-pdf.vue";
import CalendarMultiple from "../../components/calendar-multiple.vue";
import { getRequestByStatusAndQuoteRequest, getRequestByStatusAndPurchaseOrder } from "../../dataApi/requests";
import { headersMovements, headersRequests } from "../../commons/headers";
import requestView from '../../components/requestView.vue';
export default {
  components: {
    viewEmployee, ReportDataPdfBasic,
    PlusMenu, request, CalendarMultiple, requestView
  },
  data: () => ({
    dataForm: {},
    disabledForm: false,
    user: UserProfile.getUserData(),
    search: "",
    tituloDialogo: "Nuevo Movimiento",
    dialog: false,
    crateEditVer: false,
    dialogDelete: false,
    gestRequest: false,
    movements:[],
    headersMovements: headersMovements,
    headersRequests: headersRequests,
    employees: [],
    editedIndex: -1,
    editedItem: {},
    apiPath: "employs",
    categories: [],
    suppliers: [], uniqueKey: 0, keyStock: 0,
    menuItems: [], langDate: "es", dataReady: false,
    dates: [], 
    dateInit: null,
    dateEnd: null,
    itemsQuoteRequest: [],
    itemsPurchaseOrder: [],
    view: 'movements',
    requestView: null,
    keyView: 10,
    quoteRequest: false,
    purchaseOrder: false,
    purchase: false,
    medida: 4,
    gestRequestView: false
  }),
  mounted() {
    // this.editedItem.creado_por = this.user.user;
    const date = new Date();
    this.dates.push(format(date,"YYYY-MM-DD"),format(date,"YYYY-MM-DD"))
    if(this.isAdmin) this.getgetRequests();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Empleado" : "Editar Empleado";
    },
    processedItems() {
      return this.movements.map(movement => ({
        ...movement,
        date: format(movement.date, "DD-MM-YYYY", this.langDate),
      }))
    },
    groupedDesserts() {
      const grouped = [];
      for (let i = 0; i < this.processedItems.length; i += 10) {
        grouped.push(this.processedItems.slice(i, i + 10));
      }
      return grouped;
    },
    hoy(){
      const date = new Date();
      return format(date,'DD-MM-YYYY H:mm:ss', 'es')
    },
    isAdmin() {
      if(this.user.level == 'admin' || this.user.level == 'sys') {
        this.medida = 4;
        return true;
      } else {
        this.medida = 6;
        return false;
      }
      
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    processedItems(newValue) {
      if (newValue && newValue.length > 0) {
          this.dataReady = true;
        }
    }
  },

  created() {
    this.initialize();
    this.menuItems = [
      {
        title: 'Filtro de Fechas',
        icon: 'mdi-calendar-check',
        action: this.viewDialog
      },
      {
        title: 'Imprimir',
        icon: 'mdi-printer-settings',
        action: this.generatePrintQr
      }
    ];
  },

  methods: {
    async recibeTickect(item){
            this.crateEditVer = false;
            if(!item){
              this.close();
              return
            }
            item = await this.completeAndValidateItem(item)
            if(!item.id){
              axios.post(api.api + "/products", item)
                .then(response => {
                  const agregateProduct = this.agregateComplements(response.data);
                  console.log("agregamos", agregateProduct)
                  this.tickets.push(agregateProduct);
                })
            } else {
              axios.patch(`${api.api}/products/${item.id}`, item)
                .then(response => {
                  item = this.agregateComplements(item);
                  Object.assign(this.tickets[this.editedIndex], item)
                })
            }

    },

    async getgetRequests(){
      this.itemsQuoteRequest = [];
      this.itemsPurchaseOrder = [];
      this.itemsQuoteRequest = await getRequestByStatusAndQuoteRequest();
      this.itemsPurchaseOrder = await getRequestByStatusAndPurchaseOrder();
    },

    agregateComplements(itemProduct){
      const category = this.categories.find(cat => cat.value === itemProduct.categoryId)
      itemProduct.category = {name: category.text, id: category.value}
      const supplier = this.suppliers.find(sup => sup.value === itemProduct.supplierId)
      itemProduct.supplier = {name: supplier.text, id: supplier.value}
      return itemProduct
    },

    closeModal() {
      this.gestRequest = false;
      this.gestRequestView = false;
      this.keyStock++;
      this.keyView++;
      this.getgetRequests();
    },
    
    viewDialog() {
      // this.crateEditVer = true;
      // this.disabledForm = false;
      // this.dataForm = {};
      this.gestRequest = true;
    },

    viewQuoteOrPurchase(request) {
      console.log(request)
      this.quoteRequest = request.purchaseOrder ? false : true;
      this.purchaseOrder = request.purchaseOrder ? true : false;
      this.purchase = request.purchase ? true : false;
      this.requestView = request.id;
      this.keyView++;
      this.gestRequestView = true;
    },

    async viewRequest() {
      if(this.products.length === 0){
        axios
        .get(api.api + "/products")
        .then(r => {
          r.data.forEach(product => {
            this.products.push({
               text: `${product.name} - ${product.description}`,
               value: product.id
            });
          })
          this.gestRequest = true;
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        this.gestRequest = true;
      }
      
    },

    initialize() {
      axios
      .get(api.api + "/inventories/movements")
      .then(r => {
        r.data.forEach(mov => {
          this.movements.push(mov);
        })
      })
      .catch(error => {
        console.log(error);
      });
    },

    getColor(estado) {
      if (estado == "No leído") return "default";
      else if (estado == "Resuelto") return "green";
      else if (estado == "Abierto") return "orange";
      else if (estado == "Cerrado") return "red";
    },

    editItem(item) {
      this.uniqueKey++
      this.editedIndex = this.movements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dataForm = Object.assign({}, item);
      this.tituloDialogo = "Editar parametros de empleados",
      this.crateEditVer = true;
      this.disabledForm = false;
      console.log("Mandamos a editar", this.editedIndex, this.editedItem)
    },

    deleteItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      this.editedItem = item;
      this.dialogDelete = true;
    },

    viewItem(item) {
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = item;
      this.tituloDialogo = "Ver Empleado",
      this.crateEditVer = true;
      this.dataForm = item;
      this.disabledForm = true;
      console.log("se vio");
    },

    deleteItemConfirm() {
      this.tickets.splice(this.editedIndex, 1);
      axios.delete(`${api.api}/products/${this.editedItem.id}`)
      this.editedItem = Object.assign({}, this.defaultItem);
      this.closeDelete();
    },

    close() {
      console.log("cerrando")
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.tickets[this.editedIndex], this.editedItem);
      } else {
        this.tickets.push(this.editedItem);
      }
      this.close();
    },

    async completeAndValidateItem(item){
      console.log(item, this.editedItem);
      if(!item.description) item.description = "";
      if(!item.stockQuantity) item.stockQuantity = 0;
      item.price = +item.price;
      item.stockMin = +item.stockMin;
      item.stockQuantity = +item.stockQuantity;
      item.photo = item.chosenFile ? await this.uploadFile(item.chosenFile) : this.editedItem.photo == "" ? "" : this.editedItem.photo;
      delete item.category;
      delete item.supplier;
      delete item.chosenFile;
      
      return item
    },

    async uploadFile(file) {
    let form = new FormData();
    let opt = {
        headers: {
        'Accept': 'application/json','Content-Type': 'multipart/form-data',
        }
    }
    form.append('file', file);
    const response = await axios.post(`${api.api}/storage/upload`, form, opt);
    return response.data.url;
    },

    async saveStock(stock){
      this.cancelStock()
      const items = stock.items.map(item => ({
        productId: item.id,
        quantity: item.quantity
      }))
      console.log(items)
      const aggregatedStock = await axios.post(api.api+"/inventories/bulk", { items });
      if(aggregatedStock) {
        aggregatedStock.data.map(ids => {
          const productIndex = this.tickets.findIndex(prod => prod.id === ids.product.id);
          if(productIndex !== -1) this.tickets[productIndex].inventory 
            ? this.tickets[productIndex].inventory.quantity = ids.quantity 
            : this.tickets[productIndex].inventory = { quantity: ids.quantity };
          console.log(this.tickets[productIndex])
        })
      }
    },

    cancelStock(){
      this.keyStock++;
      this.gestRequest = false;
    },

    generatePrintQr(){
      this.$refs.pdfComponent.generatePdf()
    },

    saveAndClose(value){
      this.gestRequest = false;
      let dateInit, dateEnd
      if(value?.length === 1){
        dateInit = value[0];
        dateEnd = dateInit;
      } else if (value?.length === 2){
        dateInit = value[0];
        dateEnd = value[1];
      }
      if(dateInit && dateEnd) {
        axios
        .get(`${api.api}/inventories/movements/search-date?dateInit=${dateInit}&dateEnd=${dateEnd}`)
          .then(r => {
            this.movements = []
            r.data.forEach(mov => {
              this.movements.push(mov);
            })
          })
          .catch(error => {
            console.log(error);
          });
      }
    }

  },
};
</script>

<style lang="scss">
.topbar-logo {
  max-width: 150px;
}
.combo-parametros {
  .v-select__selections {
    min-height: 56px !important;
  }
}
.theme--dark.v-chip.default {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}
.v-text-field__details {
  display: none;
}

.custom-font {
  font-family: 'Your Font Family', sans-serif;
  font-size: 16px; /* ajusta el tamaño de la fuente según tus necesidades */
  text-transform: uppercase;
}
.header {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.column {
  flex: 1;
}

.logo {
  text-align: left;
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-time {
  text-align: right;
}
</style>
