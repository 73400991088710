<template>
    <div>
        <v-card flat>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page=-1
                        class="elevation-1 custom-font"
                        loading-text="Cargando... Espere por favor"
                    >
                        <template v-slot:item.quantity="{ item }">
                            <v-chip v-if="item.color" :color="item.color" text-color="white">{{ item.quantity }}</v-chip>
                            <span v-else>{{ item.quantity }}</span>
                        </template>
                    </v-data-table>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelStock">
                    Cerrar
                </v-btn>

            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    name: 'dataTableBasic',
    data: () => ({
        
    }),

    methods: {
        cancelStock(){
            this.$emit("closeModal")
        },

        async calculatePdf(){
            console.log("calculatePdf",this.quoteRequest, this.deliver, this.purchaseOrder, this.statusRequest)
            this.dataReady = true
            this.headersToPdf = [
                { text: 'Rut', value: 'employee.rut' },
                { text: 'Nombre', value: 'employee.firstName' },
                { text: 'Centro de Costo', value: 'employee.costCenter' },
                { text: 'Posicion', value: 'employee.position' },
                { text: 'Producto', value: 'product.description' },
                { text: 'Cantidad', value: 'quantity' },
            ]
            if(this.statusRequest === 'SOLICITADO'){
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'category.name' })
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Solicitud: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'AUTORIZADO'){
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Solicitud-Autorizada: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'EN PROCESO DE COMPRA'){
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Orden de Compra: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'RECIBIDO'){
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Por Entregar: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'ENTREGADO'){
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Entregado: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'RECHAZADO'){
                this.itemsDetailsToPdf = this.itemsDetails
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'category.name' })
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Rechazado: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            // if((!this.quoteRequest && !this.deliver) &&  this.purchaseOrder){
            //     const itemsBySupplier = this.classifyItemsBySupplier(this.itemsDetails)
            //     for await (const items of itemsBySupplier) {
            //         await new Promise((resolve) => {
            //             this.titlePdf = this.calculateTitlePdfToProvider(items)
            //             this.itemsDetailsToPdf = items;
            //             // setTimeout(() => {
            //             //     this.$refs.pdfComponent.generatePdf();
            //             //     resolve();
            //             // }, 1000);
            //         });
            //     }
            // } else if(this.deliver){
            //     this.headersToPdf = [
            //         { text: 'Nombre', value: 'employee.firstName' },
            //         { text: 'Apellido', value: 'employee.fatherName' },
            //         { text: 'Cantidad', value: 'quantity' },
            //         { text: 'Producto', value: 'product.description' },
            //         { text: 'Size', value: 'product.size' },
            //     ]
            //     const itemsByEmployee = this.classifyItemsByEmployee(this.itemsDetails)
            //     for await (const items of itemsByEmployee) {
            //         await new Promise((resolve) => {
            //             this.itemsDetailsToPdf = items.items;
            //             this.titlePdf = `Entregas: ${this.ref}`
            //             // setTimeout(() => {
            //             //     this.$refs.pdfComponent.generatePdf()
            //             //     resolve();
            //             // }, 1000)
            //         })
            //     }
            // } else {
            //     this.itemsDetailsToPdf = this.itemsDetails
            //     console.log(this.itemsDetailsToPdf)
            //     this.titlePdf = this.calculateTitlePdfToProvider()
            //     // setTimeout(() => {
            //     //     this.$refs.pdfComponent.generatePdf()
            //     // }, 500)
            // }
        },

        calculateTitlePdfToProvider(items = null){
            if(!items) return `Orden de Compra: ${this.ref}`;
            const supplier = items[0].product.supplier;
            return this.purchaseOrder ? `Orden de Compra: ${this.ref} - ${supplier.name}` : `Solicitud de Cotizacion: ${this.ref} - ${supplier.name}`
        },
        //TODO: trasladar logica a proccess
        async saveComment(comment){
            swal({text: "Se procede a RECHAZAR la solicitud, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
            .then(async() => {
                await updateRequest({
                    id: this.request,
                    status: 'RECHAZADO',
                    observation: comment
                })
                this.cancelStock()
            })
        },
    },

}
</script>