
import swal from "sweetalert";
import { getKeyCaseInsensitive, replaceText } from "./utils";
import { getProductsByCategoryAndSex, updateRequestItem, saveRequest, cruceProducts } from '../dataApi'

export const setAutorize = async (items, selected, request, ref) => {
    const cantSelected = selected.length
    const cantItems = items.length
    let goProcess = true
    const status = {
        detail: "",
        code: 200,
        data: null
    }
    if(cantSelected === 0){
        const shouldContinue = await swal({
            text: "Debe seleccionar al menos un item para Autorizar",
            icon: "warning",
            button: "OK"
        })
        if(shouldContinue) {
            return false
        }
    }

    if(cantSelected !== cantItems){ 
        const shouldProcess = await swal({
            text: "Se procede a autorizar los items seleccionados, los no seleccionados seran rechazados, ¿Desea continuar?", 
            icon: "info", 
            buttons: ["Cancelar", "Continuar"]
        })

        if(!shouldProcess) {
            return false
        }
    }

    const setFinal = await swal({text: "Se procede a autorizar los items seleccionados, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
    if(!setFinal) {
        return false
    }
    const cruce = await cruceProducts(selected)
    const errores = cruce.data.filter(item => item.productId === null)
    if(errores.length > 0) {
        status.data = errores
        status.code = 400
        status.detail = "Error al cruzar productos"
        status.data = subirErrores(errores)
        return status
    }
  
    if(goProcess){
        const validateStatus = ref.toLowerCase().includes('sol') ? true : false;
        let productsAvailable = [];
        let productsNotAvailable = [];
        status.detail = 'Se ha autorizado exitosamente la solicitud';
        
        console.log("SAvalidateStatus", validateStatus)
        if(validateStatus){
            cruce.data.forEach(item => {
                console.log("SAEvaluamos item", item.product)
                if(item.product?.inventory?.quantity >= item.quantity){
                    console.log("SAitem disponible", item)
                    productsAvailable.push(item)
                } else if (item.product?.inventory?.quantity > 0) {
                    console.log("SAitem parcialmente disponible", item)
                    // Crear copia del item con cantidad disponible
                    const availableItem = {...item, quantity: item.product?.inventory?.quantity}
                    productsAvailable.push(availableItem)
                        
                    // Crear copia del item con cantidad faltante
                    const notAvailableItem = {...item, quantity: item.quantity - item.product?.inventory?.quantity}
                    productsNotAvailable.push(notAvailableItem)
                } else {
                    console.log("SAitem no disponible", item)
                    productsNotAvailable.push(item)
                }
            })
            if(productsNotAvailable.length > 0 && productsAvailable.length === 0){
                const shouldContinue = await swal({
                    text: "No existen en inventario productos disponibles para autorizar",
                    icon: "error",
                    button: "OK"
                })
                if(shouldContinue) {
                    return false
                }
            }
        } else {
            productsAvailable = cruce.data
        }
        const updateRequestDto = {
            id: request,
            ref,
            status: 'AUTORIZADO',
            quoteRequest: true,
            purchaseOrder: false,
            purchase: false,
            items: productsAvailable.map(item => ({
                id: item.id,
                quantity: item.quantity,
                productId: item.productId,
                categoryId: item.category.id,
                finalEmployeeId: item.employee.id,
            }))
        }
        console.log("SAupdateRequestDto", updateRequestDto)
        await updateRequestItem(updateRequestDto);

        if(productsNotAvailable.length > 0 && validateStatus){
            console.log("SAproductsNotAvailable", productsNotAvailable)
            const refAut = replaceText(ref, 'Sol', 'Aut')
            status.detail = status.detail + ` y se ha creado la solicitud ${refAut} para la compra de los productos no disponibles`;
            const createdRequestDto = {
                ref: replaceText(ref, 'Sol', 'Aut'),
                status: 'SOLICITADO',
                quoteRequest: false,
                purchaseOrder: false,
                purchase: false,
                items: productsNotAvailable.map(item => ({
                    quantity: item.quantity,
                    productId: item.productId,
                    categoryId: item.category.id,
                    finalEmployeeId: item.employee.id,
                    observation: "Generado Aut por no Stock"
                }))
            }
            console.log("SAcreatedRequestDto", createdRequestDto)
            await saveRequest(createdRequestDto);
        }

        return {
            status
        }
    }

}

const subirErrores = (errores) => {
    console.log("SAsubirErrores", errores)
    const headers = [
        { text: 'Nombre', value: 'employeeName' },
        { text: 'Sexo', value: 'sex' },
        { text: 'Talla', value: 'size' },
        { text: 'Cantidad', value: 'quantity' },
        { text: 'Categoria', value: 'categoryName' },
        { text: 'Referencia', value: 'ref' },
    ]
    const items = errores.map(error => ({
        employeeName: error.employee.firstName + ' ' + error.employee.fatherName,
        categoryName: error.category.name,
        sex: error.employee.gender,
        size: `U:${error.employee.sizeUniform}-P:${error.employee.sizePants}-M:${error.employee.sizeMescal}`,
        quantity: error.quantity,
        ref: error.category.sizeRef
    }))
    return {
        headers,
        items
    }
}