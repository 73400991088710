import swal from "sweetalert";
import { updateRequest } from '../dataApi'

export const processPurchaseOrder = async (request) => {
    const setFinal = await swal({text: "Se procede a finalizar exitosamente la Orden de Compra, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
    
    if(!setFinal) return false

    await updateRequest({
        id: request,
        purchase: true,
        status: 'RECIBIDO'
    })

    return true
}