import swal from "sweetalert";
import { updateRequest } from '../dataApi'

export const generateDeliveries = async (request) => {
    const setFinal = await swal({text: "Se procede a finalizar exitosamente la Entrega, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
    
    if(!setFinal) return false

    await updateRequest({
        id: request,
        purchase: true,
        status: 'ENTREGADO'
    })

    return true
}