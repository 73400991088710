<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="text-h5">Comentarios</span>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <v-textarea
                                v-model="comment"
                                label="Comentario"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'comments',
    data: () => ({
        comment: '',
    }),

    methods: {
        cancel() {
            this.$emit('closeModal');
        },
        save() {
            this.$emit('saveComment', this.comment);
        },
    },
}
</script>